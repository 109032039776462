// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/dan/apps/generator/apps/blissddm/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-en-js": () => import("/home/dan/apps/generator/apps/blissddm/src/templates/project.en.js" /* webpackChunkName: "component---src-templates-project-en-js" */),
  "component---src-templates-project-es-js": () => import("/home/dan/apps/generator/apps/blissddm/src/templates/project.es.js" /* webpackChunkName: "component---src-templates-project-es-js" */),
  "component---src-templates-service-en-js": () => import("/home/dan/apps/generator/apps/blissddm/src/templates/service.en.js" /* webpackChunkName: "component---src-templates-service-en-js" */),
  "component---src-templates-service-es-js": () => import("/home/dan/apps/generator/apps/blissddm/src/templates/service.es.js" /* webpackChunkName: "component---src-templates-service-es-js" */),
  "component---src-pages-about-en-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-portfolio-en-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/portfolio.en.js" /* webpackChunkName: "component---src-pages-portfolio-en-js" */),
  "component---src-pages-portfolio-es-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/portfolio.es.js" /* webpackChunkName: "component---src-pages-portfolio-es-js" */),
  "component---src-pages-projects-en-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-projects-es-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/projects.es.js" /* webpackChunkName: "component---src-pages-projects-es-js" */),
  "component---src-pages-services-en-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-services-es-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/services.es.js" /* webpackChunkName: "component---src-pages-services-es-js" */),
  "component---src-pages-404-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-en-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-es-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-index-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-projects-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("/home/dan/apps/generator/apps/blissddm/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

