module.exports = [{
      plugin: require('/home/dan/apps/generator/apps/blissddm/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2168443386748958"},
    },{
      plugin: require('/home/dan/apps/generator/apps/blissddm/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#3399FF","showSpinner":true},
    },{
      plugin: require('/home/dan/apps/generator/apps/blissddm/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-127891134-1","head":true,"cookieDomain":"blissddm.xyz"},
    },{
      plugin: require('/home/dan/apps/generator/apps/blissddm/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/dan/apps/generator/apps/blissddm/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
